<template>
  <div class="main">
    <div class="main-info">
      <div class="main-title">园所信息</div>
      <!-- <div class="main-top">
        <div class="left">
          <div class="main-top-item">
            <div
              class="left-item grade-num"
              :class="{ active: curItem === 0 }"
            >
              <p class="name">{{ nameArr[0] }}</p>
              <p class="value">{{ $store.state.schoolInfo.gradeCount || 0 }}</p>
            </div>
            <p v-if="!$store.state.schoolInfo.gradeCount">您还未新建班级~</p>
          </div>
          <div class="main-top-item">
            <div
              class="left-item teacher-num"
              :class="{ active: curItem === 1 }"
            >
              <p class="name">{{ nameArr[1] }}</p>
              <p class="value">{{ $store.state.schoolInfo.teaCount || 0 }}</p>
            </div>
            <p v-if="!$store.state.schoolInfo.teaCount">您还未导入教师~</p>
          </div>
          <div class="main-top-item">
            <div
              class="left-item stu-num"
              :class="{ active: curItem === 2 }"
            >
              <p class="name">{{ nameArr[2] }}</p>
              <p class="value">{{ $store.state.schoolInfo.stuCount || 0 }}</p>
            </div>
            <p v-if="!$store.state.schoolInfo.stuCount">
              当前园所幼儿过少，请登录教师账号导入幼儿~
            </p>
          </div>
          <div class="main-top-item">
            <div
              class="left-item attend-num"
              :class="{ active: curItem === 4 }"
            >
              <p class="name">{{ nameArr[4] }}</p>
              <p class="value">{{ info.totalStudyCount || 0 }}</p>
            </div>
            <p v-if="!info.totalStudyCount">
              当前上课人数过少，请及时购课配课以便幼儿上课和测试。
            </p>
          </div>
          <div class="main-top-item">
            <div
              class="left-item test-num"
              :class="{ active: curItem === 5 }"
            >
              <p class="name">{{ nameArr[5] }}</p>
              <p class="value">{{ info.totalTestCount || 0 }}</p>
            </div>
            <p v-if="!info.totalTestCount">
              未做测试的幼儿进行测试和及时录入测试数据~
            </p>
          </div>
          <div class="main-top-item">
            <div
              class="left-item uninfo-stu-num"
              :class="{ active: curItem === 3 }"
            >
              <p class="name">{{ nameArr[3] }}</p>
              <p class="value">
                {{ $store.state.schoolInfo.noStudentInfo || 0 }}
              </p>
            </div>
            <p v-if="!$store.state.schoolInfo.noStudentInfo">
              请及时完善幼儿信息，以便配课幼儿上课和测试～
            </p>
          </div>
        </div>
      </div> -->
      <SchoolInfoNewOne></SchoolInfoNewOne>
    </div>
    <div class="main-manage">
      <div class="main-title-admin">园所管理</div>
      <div class="main-bottom">
        <SchoolPark />
      </div>
    </div>
  </div>
</template>
<script>
// import echarts from "../../../utils/echartsUi.js";
import SchoolPark from "./schoolPark.vue";
import SchoolInfoNewOne from "./schoolInfoNewOne.vue";
export default {
  name: "schoolInfo",
  data() {
    return {
      info: {},
      curItem: 0,
      myChart: "",
      nameArr: [
        "班级数量",
        "教师人数",
        "录入幼儿数",
        "未完善信息幼儿",
        "总上课人数",
        "总测试人数",
      ],
    };
  },
  components: {
    SchoolPark,
    SchoolInfoNewOne,
  },
  methods: {
    //折线图
    drawLine(timeArr, valArr) {
      // 绘制图表
      this.myChart.setOption({
        xAxis: {
          data: timeArr,
          type: "category",
          axisTick: { show: false },
          axisLine: {
            lineStyle: {
              color: "#333333",
              type: "dashed",
              opacity: 0.1,
            },
          },
        },
        yAxis: {
          splitLine: {
            lineStyle: {
              color: "#FF5E66",
              type: "dashed",
              opacity: 0.1,
            },
          },
          axisLine: {
            lineStyle: {
              color: "#FF5E66",
              type: "dashed",
              opacity: 0.1,
            },
          },
        },
        series: [
          {
            name: "销量",
            type: "line",
            data: valArr,
            lineStyle: {
              color: "#FF5E66",
            },
            itemStyle: {
              color: "#FF5E66",
              shadowBlur: 6,
              shadowColor: "#FF5E66",
            },
            symbol: "circle",
            symbolSize: 8,
          },
        ],
        grid: {
          top: "15%",
          left: "5%",
          right: "5%",
          bottom: "10%",
          containLabel: true,
        },
      });
    },
    //学校信息
    getSchoolBaseinfo() {
      this.api.nAdmin
        .getSchoolBaseinfo({ schoolId: this.$store.state.userInfo.schoolId })
        .then((res) => {
          this.info = res.data;
        });
    },
    //获取折线图数据
    getItemInfo(num) {
      // console.log(data,'ddddd');
      this.curItem = num;
      let data = {
        school_id: this.$store.state.userInfo.schoolId,
        // school_id:11,
        type: num,
      };
      this.api.nAdmin.findByType(data).then((res) => {
        res.data[0] = res.data[0].map((item) => {
          item = item.split(" ")[0];
          return item;
        });
        this.drawLine(res.data[0], res.data[1]);
      });
    },
  },
  mounted() {
    // 基于准备好的dom，初始化echarts实例
    // this.myChart = echarts.init(document.getElementById("myChart"));
    this.getSchoolBaseinfo();
    // this.getItemInfo(this.curItem);
    // this.drawLine();
  },
};
</script>
<style scoped lang="scss">
.main {
  // background: #ffffff;
  .main-info {
    margin-bottom: 16px;
    padding: 40px;
    background: #FFFFFF;
  }
  .main-manage {
    background: #ffffff;
    padding: 40px;
  }
  .main-top {
    // box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.41);
    border-radius: 2px;
    margin-bottom: 23px;
  }
  .left {
    @include flex(row, space-between, flex-start);
  }
  // .left-l {
  // margin-left:24px;
  // padding-top: 15px;
  // width: 630px;
  // @include flex(row, flex-start, flex-start);
  .main-top-item {
    width: 214px;
    > p {
      font-size: 14px;
      color: #888888;
    }
  }
  .left-item {
    margin-bottom: 11px;
    width: 214px;
    height: 98px;
    background: #ffffff;
    box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    font-size: 18px;
    color: #888888;
    display: inline-block;
    // cursor: pointer;
    .name {
      margin-top: 20px;
      padding-left: 20px;
      font-size: 18px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #888888;
    }
    .value {
      padding-left: 20px;
      font-size: 28px;
      font-family: Adobe Heiti Std;
      font-weight: bold;
      color: #333333;
    }
  }
  .grade-num {
    background: url("../../../assets/img/classHome/Icon_ClaNum.png") no-repeat
      156px 36px;
    background-size: 44px 51px;
  }
  // .grade-num.active,
  // .grade-num:hover {
  //   background: url("../../../assets/img/classHome/Icon_ClaNum_UnSel.png")
  //     no-repeat left top;
  //   background-size: 76px 82px;
  //   .value{
  //     color:#ba3037;
  //   }
  // }
  .teacher-num {
    background: url("../../../assets/img/classHome/Icon_TeacherNum.png")
      no-repeat 156px 36px;
    background-size: 44px 51px;
  }
  // .teacher-num.active,
  // .teacher-num:hover {
  //   background: url("../../../assets/img/classHome/Icon_TeaNum_UnSel.png")
  //     no-repeat left top;
  //   background-size: 76px 82px;
  //   .value{
  //     color:#ba3037;
  //   }
  // }
  .stu-num {
    background: url("../../../assets/img/classHome/Icon_LuRNum.png") no-repeat
      156px 36px;
    background-size: 44px 51px;
  }
  // .stu-num.active,
  // .stu-num:hover {
  //   background: url("../../../assets/img/classHome/Icon_LurNum_UnSel.png")
  //     no-repeat left top;
  //   background-size: 76px 82px;
  //   .value{
  //     color:#ba3037;
  //   }
  // }
  .uninfo-stu-num {
    background: url("../../../assets/img/classHome/Icon_SKRSNum_UnSel.png")
      no-repeat 156px 36px;
    background-size: 44px 51px;
  }
  // .uninfo-stu-num.active,
  // .uninfo-stu-num:hover {
  //   background: url("../../../assets/img/classHome/Icon_WwsNum_UnSel.png")
  //     no-repeat left top;
  //   background-size: 76px 82px;
  //   .value{
  //     color:#ba3037;
  //   }
  // }
  .attend-num {
    background: url("../../../assets/img/classHome/Icon_ZCSNum.png") no-repeat
      156px 36px;
    background-size: 44px 51px;
  }
  // .attend-num.active,
  // .attend-num:hover {
  //   background: url("../../../assets/img/classHome/Icon_ZskNum_UnSel.png")
  //     no-repeat left top;
  //   background-size: 76px 82px;
  //   .value{
  //     color:#ba3037;
  //   }
  // }
  .test-num {
    background: url("../../../assets/img/classHome/Icon_UnFNum_UnSel.png")
      no-repeat 156px 36px;
    background-size: 44px 51px;
  }
  // .test-num.active,
  // .test-num:hover {
  //   background: url("../../../assets/img/classHome/Icon_ZcsNum_UnSel.png")
  //     no-repeat left top;
  //   background-size: 76px 82px;
  //   .value{
  //     color:#ba3037;
  //   }

  // }
  // }
  .right {
    // margin-top: 32px;
    margin-right: 30px;
    width: 608px;
    height: 300px;
    background: #ffffff;
    box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.08);
    border-radius: 6px;

    .right-tit {
      padding-left: 14px;
      height: 36px;
      line-height: 36px;
      font-size: 18px;
      color: #ffffff;
      background: #ba3037;
      border-radius: 6px 6px 0 0;
    }
  }
}
.main-title {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  // color: #ba3037;
  color: #364479;
  padding-bottom: 23px;
}
.main-title-admin {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ba3037;
  padding-bottom: 23px;
}
</style>