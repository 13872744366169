<template>
  <div class="school-info">
    <ul class="school-info-ul">
      <li
        class="school-info-ul-li"
        v-for="item in infoList"
        :key="item.id"
        @click="changeList(item)"
      >
        <div
          class="school-info-ul-li-box"
          :class="[item.class, curId === item.id ? 'active' : '']"
        >
          <div>
            {{ item.label }}
          </div>
        </div>
        <div class="school-info-ul-li-circle" v-if="curId === item.id"></div>
      </li>
    </ul>
    <div class="school-info-cont">
      <div class="school-info-cont-status" v-if="curId == 1">
        <div class="school-info-cont-status-l">
          <img
            v-if="info.status == '0'"
            class="school-info-cont-status-l-img"
            src="../../../assets/img/newschool/Pic_RZ_WRZ.png"
            alt=""
          />
          <img
            v-if="info.status == '1'"
            class="school-info-cont-status-l-img"
            src="../../../assets/img/newschool/Pic_RZ_SHZ.png"
            alt=""
          />
          <img
            v-if="info.status == '88'"
            class="school-info-cont-status-l-img"
            src="../../../assets/img/newschool/Pic_RZZT_RZTG.png"
            alt=""
          />
          <img
            v-if="info.status == '2'"
            class="school-info-cont-status-l-img"
            src="../../../assets/img/newschool/Pic_RZZT_RZSB.png"
            alt=""
          />
          <div class="school-info-cont-status-l-val">
            <p>{{ authenticationStatus[info.status] }}</p>
            <p
              class="school-info-cont-status-l-tip"
              :class="info.status == '0' || info.status == '2' ? 'tips' : ''"
              :title="authenticationTip[info.status]"
            >
              {{ authenticationTip[info.status] }}
            </p>
          </div>
        </div>
        <div
          class="school-info-cont-status-r"
          v-if="info.status == '0' || info.status == '2'"
          @click="toJump('/user')"
        >
          {{ info.status == "2" ? "修改资料" : "去认证" }}
        </div>
      </div>
      <div class="school-info-cont-info" v-if="curId == 2">
        <div class="school-info-cont-info-item mr44">
          <div class="school-info-cont-info-item-l">
            <p>
              班级数量：
              <span>{{ $store.state.schoolInfo.gradeCount || 0 }}</span>
            </p>
            <p v-if="!$store.state.schoolInfo.gradeCount">未新建班级</p>
          </div>
          <div class="school-info-cont-info-item-r" @click="goCurPage(0)">
            新建班级
          </div>
        </div>
        <div class="school-info-cont-info-item mr44">
          <div class="school-info-cont-info-item-l">
            <p>
              教师数量：
              <span>{{ $store.state.schoolInfo.teaCount || 0 }}</span>
            </p>
            <p v-if="!$store.state.schoolInfo.teaCount">未导入教师</p>
          </div>
          <div class="school-info-cont-info-item-r" @click="goCurPage(1)">
            导入教师
          </div>
        </div>
        <div class="school-info-cont-info-item">
          <div class="school-info-cont-info-item-l">
            <p>
              幼儿数量：
              <span>{{ $store.state.schoolInfo.stuCount || 0 }}</span>
            </p>
            <p v-if="!$store.state.schoolInfo.stuCount">未导入幼儿</p>
            <p v-else>
              {{
                $store.state.schoolInfo.gradeCountStatus == 0
                  ? "导入幼儿数量不足"
                  : ""
              }}
            </p>
          </div>
          <div class="school-info-cont-info-item-r" @click="goCurPage(2)">
            导入幼儿
          </div>
        </div>
      </div>
      <div class="school-info-cont-status" v-if="curId == 3">
        <div class="school-info-cont-status-l">
          <img
            class="school-info-cont-status-l-img"
            src="../../../assets/img/newschool/Pic_GMKC.png"
            alt=""
          />
          <div class="school-info-cont-status-l-val">
            <p>已购买课程ID：{{ info.payIds }}</p>
            <p class="school-info-cont-status-l-tip tips" v-if="!info.payIds">
              未购课
            </p>
          </div>
        </div>
        <div
          class="school-info-cont-status-r"
          @click="toJump('/admin/classroom/classIndex')"
        >
          购买课程
        </div>
      </div>
      <div class="school-info-cont-setcourse" v-if="curId == 4">
        <div class="school-info-cont-info-item setcourse-w mr44">
          <div class="school-info-cont-info-item-l">
            <p>
              已配课幼儿：
              <span>{{ info.stuWithClass }}</span>
            </p>
            <p
              v-if="
                Number(info.stuWithClass.split('/')[0]) <
                Number(info.stuWithClass.split('/')[1])
              "
            >
              未配课无法正常上课
            </p>
          </div>
          <div
            class="school-info-cont-info-item-r"
            v-if="
              Number(info.stuWithClass.split('/')[0]) <
              Number(info.stuWithClass.split('/')[1])
            "
            @click="toJump('/admin/classroom/classIndex')"
          >
            配课
          </div>
        </div>
        <div class="school-info-cont-info-item setcourse-w">
          <div class="school-info-cont-info-item-l">
            <p>
              已配课未完善信息幼儿：
              <span>{{ $store.state.schoolInfo.planStuCount }}</span>
            </p>
            <p
              v-if="
                Number($store.state.schoolInfo.planStuCount.split('/')[0]) > 0
              "
            >
              信息不完整，无法正常上课和测试
            </p>
          </div>
          <div
            class="school-info-cont-info-item-r"
            v-if="
              Number($store.state.schoolInfo.planStuCount.split('/')[0]) > 0
            "
            @click="goCurPage(3)"
          >
            去完善
          </div>
        </div>
      </div>
      <div class="school-info-cont-status" v-if="curId == 5">
        <div class="school-info-cont-status-l">
          <img
            class="school-info-cont-status-l-img"
            src="../../../assets/img/newschool/Pic_ZXSK_KCJD.png"
            alt=""
          />
          <div class="school-info-cont-status-l-val">
            <el-dropdown size="medium" v-if="gradeList.length">
              <span class="el-dropdown-link">
                {{ curGrade.name
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu
                slot="dropdown"
                style="height: 270px; overflow-y: auto"
              >
                <el-dropdown-item
                  style="font-size: 16px"
                  v-for="item in gradeList"
                  :key="item.id"
                  @click.native="changeGradeList(item)"
                  >{{ item.name }}</el-dropdown-item
                >
                <!-- <el-dropdown-item style="font-size:16px;">狮子头</el-dropdown-item>
                                <el-dropdown-item style="font-size:16px;">螺蛳粉</el-dropdown-item>
                                <el-dropdown-item style="font-size:16px;">双皮奶</el-dropdown-item>
                                <el-dropdown-item style="font-size:16px;">蚵仔煎</el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
            <p>上课进度：{{ classProcess }}</p>
            <p
              class="school-info-cont-status-l-tip tips"
              style="margin-top: 0"
              v-if="
                Number(classProcess.split('/')[0]) <
                Number(classProcess.split('/')[1])
              "
            >
              上课进度滞后
            </p>
          </div>
        </div>
        <div
          class="school-info-cont-status-r"
          @click="toJump('/admin/classroom/classIndex', 'isTeacher')"
          v-if="
            Number(classProcess.split('/')[0]) <
            Number(classProcess.split('/')[1])
          "
        >
          开始上课
        </div>
      </div>
      <div class="school-info-cont-status" v-if="curId == 6">
        <!-- <div class="school-info-cont-info-item test-w mr22">
          <div class="school-info-cont-info-item-l">
            <p>学期初体质测试</p>
          </div>
          <div
            class="school-info-cont-info-item-r"
            v-if="info.semesterStatus == 0 || info.semesterStatus == 1"
            @click="toJump('/admin/testconter/padContent/corporeityTest/corporeityTestA', 'isTeacher')"
          >
            开始测试
          </div>
          <div v-else style="color: red; font-size: 18px">未开始</div>
        </div>
        <div class="school-info-cont-info-item test-w mr22">
          <div class="school-info-cont-info-item-l">
            <p>学期初运动测试</p>
            
          </div>
          <div
            class="school-info-cont-info-item-r"
            v-if="info.semesterStatus == 0 || info.semesterStatus == 1"
            @click="toJump('/admin/testconter/padContent/exerciseTest/exerciseTestA', 'isTeacher')"
          >
            开始测试
          </div>
          <div v-else style="color: red; font-size: 18px">未开始</div>
        </div>
        <div class="school-info-cont-info-item test-w mr22">
          <div class="school-info-cont-info-item-l">
            <p>学期末体质测试</p>
            
          </div>
          <div
            class="school-info-cont-info-item-r"
            v-if="info.semesterStatus == 1"
            @click="toJump('/admin/testconter/padContent/corporeityTest/corporeityTestA', 'isTeacher')"
          >
            开始测试
          </div>
          <div v-else style="color: red; font-size: 18px">未开始</div>
        </div>
        <div class="school-info-cont-info-item test-w">
          <div class="school-info-cont-info-item-l">
            <p>学期末运动测试</p>
            
          </div>
          <div
            class="school-info-cont-info-item-r"
            v-if="info.semesterStatus == 1"
            @click="toJump('/admin/testconter/padContent/exerciseTest/exerciseTestA', 'isTeacher')"
          >
            开始测试
          </div>
          <div v-else style="color: red; font-size: 18px">未开始</div>
        </div> -->
        <div class="school-info-cont-status-l">
          <img
            class="school-info-cont-status-l-img"
            src="../../../assets/img/newschool/Pic_YSGL_ZXCS.png"
            alt=""
          />
          <div class="school-info-cont-status-l-val">
            <p>幼儿测试录入</p>
            <!-- <p class="school-info-cont-status-l-tip tips">未完成全员测试</p> -->
          </div>
        </div>
        <div
          class="school-info-cont-status-r"
          @click="toJump1('/admin/testconter/testconterIndex/')"
        >
          开始测试
        </div>
      </div>
      <div class="school-info-cont-status" v-if="curId == 7">
        <div class="school-info-cont-status-l">
          <img
            class="school-info-cont-status-l-img"
            src="../../../assets/img/newschool/Pic_CSBG.png"
            alt=""
          />
          <div class="school-info-cont-status-l-val">
            <p>幼儿测试报告</p>
            <!-- <p class="school-info-cont-status-l-tip tips">未完成全员测试</p> -->
          </div>
        </div>
        <div
          class="school-info-cont-status-r"
          @click="toJump1('/admin/testconter/testconterIndex/')"
        >
          查看报告
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "schoolInfoNewOne",
  data() {
    return {
      infoList: [
        {
          id: 1,
          label: "认证状态",
          class: "status",
        },
        {
          id: 2,
          label: "完善信息",
          class: "info",
        },
        {
          id: 3,
          label: "购买课程",
          class: "course",
        },
        {
          id: 4,
          label: "幼儿配课",
          class: "setCourse",
        },
        {
          id: 5,
          label: "在线上课",
          class: "online",
        },
        {
          id: 6,
          label: "在线测试",
          class: "test",
        },
        {
          id: 7,
          label: "测试报告",
          class: "report",
        },
      ],
      curId: 1,
      authenticationStatus: {
        88: "认证通过",
        0: "未认证",
        1: "审核中",
        2: "认证失败",
      },
      authenticationTip: {
        88: "开始园所管理",
        0: "园所还未认证",
        1: "资料已提交，请耐心等待 ",
        2: "",
      },
      info: {
        costOrderStatus: "",
        gradeCount: "",
        payIds: "",
        planStuCount: "",
        reportCount: "",
        status: "",
        statusMsg: "",
        stuWithClass: "",
        studentCount: "",
        teacherCount: "",
      },
      curPageTip: [
        "点击“园所管理”-“新建班级”进行添加。",
        "点击“全部班级”-“导入教师”进行添加。",
        "选择班级，点击“导入幼儿”进行添加。",
        "点击“其他”-“已配课未完善信息”进行完善",
      ],
      gradeList: [],
      curGrade: {}, //当前班级
      classProcess: "0/0", //上课进度
    };
  },
  methods: {
    changeList(data) {
      this.curId = data.id;
    },
    //学校信息
    getSchoolInfo() {
      //获取详情
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.nAdmin.findNavGuidanceBySchoolId(data).then((res) => {
        if (res.flag) {
          if (res.data.status == 2) {
            this.authenticationTip[res.data.status] = res.data.statusMsg;
          }
          this.info = res.data;
        }
      });
    },
    toJump(url, isTeacher) {
      //跳转页面
      if (location.pathname === url) return;
      if (isTeacher) {
        if (!this.$store.state.userInfo.teacherId) {
          this.$notice({
            // type: "success",
            message: "请用教师账号登录进行操作",
          }).isShow();
          return;
        }
      } else {
        if (this.$store.state.userInfo.teacherId) {
          this.$notice({
            // type: "success",
            message: "请用园长账号登录进行操作",
          }).isShow();
          return;
        }
      }
      this.$router.push({
        path: url,
      });
    },
    toJump1(url) {
      this.$router.push({
        path: url,
      });
    },
    goCurPage(num) {
      //跳转当前页面功能提示
      this.$notice({
        type: "success",
        message: this.curPageTip[num],
      }).isShow();
    },
    changeGradeList(data) {
      //切换学校列表
      this.curGrade = data;
      this.findGradeProgress();
    },
    getGradeList() {
      //获取班级列表
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.nAdmin.findSelectBySchoolId(data).then((res) => {
        if (res.flag) {
          this.gradeList = res.data;
          if (this.gradeList.length) {
            this.curGrade = this.gradeList[0];
            this.findGradeProgress();
          }
        }
      });
    },
    findGradeProgress() {
      //获取当前班级上课进度
      let data = {
        gradeId: this.curGrade.id,
      };
      this.api.nAdmin.findGradeProgress(data).then((res) => {
        if (res.flag) {
          this.classProcess = res.data;
        } else {
          this.$notice({
            message: res.message,
          }).isShow();
        }
      });
    },
  },
  mounted() {
    this.getSchoolInfo();
    this.getGradeList();
  },
};
</script>
<style lang="scss" scoped>
.school-info {
  .school-info-ul {
    height: 96px;
    border-bottom: 2px dashed rgba(47, 61, 109, 0.3);
    @include flex(row, flex-start, flex-start);
    .school-info-ul-li {
      // margin:0 0 0 -1px;
      width: 196px;
      height: 90px;
      line-height: 90px;

      position: relative;

      .school-info-ul-li-box {
        width: 196px;
        background: url("../../../assets/img/newschool/Btn_Bg_Mid_Unc.png")
          no-repeat;
        background-size: cover;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        cursor: pointer;
        > div {
          margin-left: 20px;
          padding-left: 52px;
        }
      }
      .status {
        > div {
          background: url("../../../assets/img/newschool/Icon_RZZT_U.png")
            no-repeat left center;
          background-size: 42px 54px;
        }
      }
      .info {
        > div {
          background: url("../../../assets/img/newschool/Icon_WSXX_U.png")
            no-repeat left center;
          background-size: 42px 54px;
        }
      }
      .course {
        > div {
          background: url("../../../assets/img/newschool/Icon_GMKC_U_new.png")
            no-repeat left center;
          background-size: 42px 54px;
        }
      }
      .setCourse {
        > div {
          background: url("../../../assets/img/newschool/Icon_YEPK_U.png")
            no-repeat left center;
          background-size: 42px 54px;
        }
      }
      .online {
        > div {
          background: url("../../../assets/img/newschool/Icon_ZXSK_U.png")
            no-repeat left center;
          background-size: 42px 54px;
        }
      }
      .test {
        > div {
          background: url("../../../assets/img/newschool/Icon_ZXCS_U.png")
            no-repeat left center;
          background-size: 42px 54px;
        }
      }
      .report {
        > div {
          background: url("../../../assets/img/newschool/Icon_CSBG_U.png")
            no-repeat left center;
          background-size: 42px 54px;
        }
      }
      .school-info-ul-li-box:hover,
      .school-info-ul-li-box.active {
        background: url("../../../assets/img/newschool/Btn_Bg_Mid_C.png")
          no-repeat;
        background-size: cover;
        color: #ffffff;
        > div {
          background: url("../../../assets/img/newschool/Icon_RZZT_C.png")
            no-repeat left center;
          background-size: 42px 54px;
        }
      }
      .status:hover,
      .status.active {
        > div {
          background: url("../../../assets/img/newschool/Icon_RZZT_C.png")
            no-repeat left center;
          background-size: 42px 54px;
        }
      }
      .info:hover,
      .info.active {
        > div {
          background: url("../../../assets/img/newschool/Icon_WSXX_C.png")
            no-repeat left center;
          background-size: 42px 54px;
        }
      }
      .course:hover,
      .course.active {
        > div {
          background: url("../../../assets/img/newschool/Icon_GMKC_C.png")
            no-repeat left center;
          background-size: 42px 54px;
        }
      }
      .setCourse:hover,
      .setCourse.active {
        > div {
          background: url("../../../assets/img/newschool/Icon_YEPK_C.png")
            no-repeat left center;
          background-size: 42px 54px;
        }
      }
      .online:hover,
      .online.active {
        > div {
          background: url("../../../assets/img/newschool/Icon_ZXSK_C.png")
            no-repeat left center;
          background-size: 42px 54px;
        }
      }
      .test:hover,
      .test.active {
        > div {
          background: url("../../../assets/img/newschool/Icon_ZXCS_C.png")
            no-repeat left center;
          background-size: 42px 54px;
        }
      }
      .report:hover,
      .report.active {
        > div {
          background: url("../../../assets/img/newschool/Icon_CSBG_C.png")
            no-repeat left center;
          background-size: 42px 54px;
        }
      }
      .school-info-ul-li-circle {
        width: 28px;
        height: 28px;
        background: url("../../../assets/img/newschool/Ic_Zs_Circle.png")
          no-repeat left center;
        background-size: 28px 28px;
        margin: -6px auto 0 auto;
        // position: absolute;
        // left: 50%;
        // margin-left:-14px;
        // top:74px;
      }
    }
    .school-info-ul-li:nth-child(1) {
      margin-left: -15px;
      .school-info-ul-li-box {
        // width: 206px;
        background: url("../../../assets/img/newschool/Btn_Bg_L_U.png")
          no-repeat;
        background-size: cover;
      }
      .school-info-ul-li-box:hover,
      .school-info-ul-li-box.active {
        background: url("../../../assets/img/newschool/Btn_bg_L_C.png")
          no-repeat;
        background-size: cover;
      }
    }
    .school-info-ul-li:nth-child(7) {
      .school-info-ul-li-box {
        background: url("../../../assets/img/newschool/Btn_bg_R_U.png")
          no-repeat;
        background-size: cover;
      }
      .school-info-ul-li-box:hover,
      .school-info-ul-li-box.active {
        background: url("../../../assets/img/newschool/Btn_CSBG_R_C.png")
          no-repeat;
        background-size: cover;
      }
    }
  }
  .school-info-cont {
    margin-top: 56px;
    margin-bottom: 20px;
    min-height: 120px;
    @include flex(row, center, center);
    .school-info-cont-status {
      @include flex(row, flex-start, center);
      .school-info-cont-status-l {
        @include flex(row, flex-start, center);
        .school-info-cont-status-l-img {
          width: 84px;
          height: 84px;
        }
        .school-info-cont-status-l-val {
          margin-left: 20px;
          font-size: 30px;
          font-weight: bold;
          color: #333333;

          .school-info-cont-status-l-tip {
            margin-top: 10px;
            font-size: 18px;
            font-weight: normal;
            color: #888888;
            word-wrap: break-word;
            max-width: 400px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* 这个表示要显示几行 */
            -webkit-box-orient: vertical;
          }
          .school-info-cont-status-l-tip.tips {
            color: #ba3037;
          }
        }
      }
      .school-info-cont-status-r {
        margin-left: 300px;
        width: 122px;
        height: 54px;
        background: url("../../../assets/img/newschool/Btn_bg_U_new.png")
          no-repeat;
        background-size: cover;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 54px;
        cursor: pointer;
      }
    }
    .school-info-cont-info,
    .school-info-cont-setcourse,
    .school-info-cont-test {
      @include flex(row, space-between, center);
      .school-info-cont-info-item {
        // margin-right:44px;
        padding: 18px;
        width: 325px;
        height: 69px;
        background: #ffffff;
        box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        @include flex(row, space-between, center);
        .school-info-cont-info-item-l {
          font-size: 24px;
          font-weight: 400;
          color: #333333;
          > p:nth-child(1) {
            > span {
              padding-left: 5px;
            }
          }
          > p:nth-child(2) {
            margin-top: 10px;
            font-size: 18px;
            color: #ba3037;
          }
        }
        .school-info-cont-info-item-r {
          width: 110px;
          height: 37px;
          text-align: center;
          line-height: 39px;
          border: 1px solid #4e63aa;
          border-radius: 19px;
          font-size: 18px;
          font-weight: 400;
          color: #4e63aa;
          cursor: pointer;
        }
        .school-info-cont-info-item-r:hover {
          background: #607ec4;
          color: #ffffff;
        }
      }
      .setcourse-w {
        width: 465px;
      }

      .test-w {
        width: 284px;
      }
    }
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #667dbb;
    font-size: 20px;
  }
  .el-icon-arrow-down {
    font-size: 20px;
    color: #667dbb;
  }
}
.mr22 {
  margin-right: 22px !important;
}
.mr44 {
  margin-right: 44px;
}
</style>